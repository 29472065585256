// ModalUpdateFinancial.tsx
import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import { FiDelete } from "react-icons/fi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface ModalUpdateFinancialProps {
  open: boolean;
  handleClose: () => void;
  branches: { key: string; title: string }[];
  selectedRecord: any;
  setSelectedRecord: React.Dispatch<React.SetStateAction<any>>;
  fileName: string | null;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: () => void;
  handleSubmitUpdate: () => void;
  userRole: string | null;
}

const ModalUpdateFinancial: React.FC<ModalUpdateFinancialProps> = ({
  open,
  handleClose,
  branches,
  selectedRecord,
  setSelectedRecord,
  fileName,
  handleFileChange,
  handleDeleteFile,
  handleSubmitUpdate,
  userRole,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Cập nhật tài chính
        </Typography>

        <TextField
          fullWidth
          label="Tiêu đề"
          value={selectedRecord?.finance_name || ""}
          onChange={(e) =>
            setSelectedRecord({
              ...selectedRecord,
              finance_name: e.target.value,
            })
          }
          variant="outlined"
          sx={{ mb: 2 }}
        />

        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <FormControl fullWidth>
            <InputLabel id="finance-type-label">Loại tài chính</InputLabel>
            <Select
              labelId="finance-type-label"
              value={selectedRecord?.finance_type || ""}
              onChange={(e) =>
                setSelectedRecord((prev: any) => ({
                  ...prev,
                  finance_type: e.target.value,
                }))
              }
              label="Loại tài chính"
            >
              <MenuItem value="revenue">Doanh thu</MenuItem>
              <MenuItem value="income">Thu nhập</MenuItem>
              <MenuItem value="report">Báo cáo</MenuItem>
            </Select>
          </FormControl>

          {userRole === "super-admin" ? (
            <FormControl fullWidth>
              <InputLabel id="branch-type-label">Chi nhánh</InputLabel>
              <Select
                labelId="branch-type-label"
                value={selectedRecord?.branch || ""}
                onChange={(e) =>
                  setSelectedRecord((prev: any) => ({
                    ...prev,
                    branch: e.target.value,
                  }))
                }
                label="Chi nhánh"
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.key} value={branch.key}>
                    {branch.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Chọn ngày"
            value={dayjs(selectedRecord?.finance_date) || ""}
            onChange={(newValue) =>
              setSelectedRecord((prev: any) => ({
                ...prev,
                finance_date: newValue,
              }))
            }
            sx={{ width: "100%", mb: 2 }}
          />
        </LocalizationProvider>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "40px",
            cursor: "pointer",
            mb: 6,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border: "2px dashed #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              transition: "background-color 0.3s",
            }}
            onClick={() => document.getElementById("fileInput")?.click()}
          >
            <Typography>Nhấn vào đây để tải tài liệu</Typography>

            <input
              id="fileInput"
              type="file"
              accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>

          {fileName ? (
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ flex: 1 }}>
                Tên file: <strong>{fileName}</strong>
              </Typography>
              <IconButton
                color="error"
                onClick={handleDeleteFile}
                sx={{
                  padding: 0,
                  marginLeft: "10px",
                  width: "24px",
                  height: "24px",
                }}
              >
                <FiDelete />
              </IconButton>
            </Box>
          ) : selectedRecord && selectedRecord.media_id ? (
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ flex: 1 }}>
                Tên file hiện tại:{" "}
                <strong>{selectedRecord.media_id?.media_file_name}</strong>
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmitUpdate}
          sx={{
            textTransform: "none",
            fontWeight: 550,
            bgcolor: "#FF1919",
          }}
        >
          Xác nhận
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalUpdateFinancial;
