import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BranchIT } from "../Branch/interface";
import { getBranchConfig } from "../Branch/service";
import { useToken } from "src/utils/token";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createFinancial,
  deleteFinancial,
  getFinancial,
  updateFinancial,
  uploadMedia,
} from "./service";
import { FiDelete, FiEdit, FiTrash2 } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import dayjs, { Dayjs } from "dayjs";

import { TbFileTypeDocx } from "react-icons/tb";
import { FaFileExcel } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa6";
import { useSnackbar } from "src/components/CustomSnackbar";
import FinancialModal from "./components/ModalCreateFinancial";
import ModalUpdateFinancial from "./components/ModalUpdateFinancial";

const getFileIcon = (fileName: string | undefined) => {
  if (!fileName) return null;

  const extension = fileName.split(".").pop()?.toLowerCase() || "";

  if (["docx", "doc", "docs"].includes(extension)) {
    return <TbFileTypeDocx fontSize={20} color="#2D5D9B" />;
  }
  if (["xls", "xlsx", "csv"].includes(extension)) {
    return <FaFileExcel fontSize={20} color="#1C7430" />;
  }
  if (extension === "pdf") {
    return <FaFilePdf fontSize={20} color="#D32F2F" />;
  }

  return null;
};

const Financial = () => {
  const token = useToken();
  const { showSnackbar } = useSnackbar();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const [openFinancial, setOpenFinancial] = useState<boolean>(false);
  const [financialData, setFinancialData] = useState<any[]>([]);
  const [title, setTitle] = useState<string>("");
  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedBranchHome, setSelectedBranchHome] = useState<string>("");
  const [financeTypeHome, setFinanceTypeHome] = useState<string>("");
  const [financeType, setFinanceType] = useState<string>("");
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const [mediaId, setMediaId] = useState<string>("");
  const [financeDate, setFinanceDate] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    dayjs()
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(dayjs());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [financialRecords, setFinancialRecords] = useState<any[]>([]);
  const [openFinancialUpdate, setOpenFinancialUpdate] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const stackRef = useRef<HTMLDivElement | null>(null);

  const selectedStartDateRef = useRef<Dayjs | null>(selectedStartDate);
  const selectedEndDateRef = useRef<Dayjs | null>(selectedEndDate);
  const fetchBranches = async () => {
    try {
      const data = await getBranchConfig("branch", token || "");
      setBranches(data.config.option_content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchFinancialData = async () => {
    const branchToSend =
      userRole === "super-admin" ? selectedBranchHome : userBranch;

    setLoading(true);
    try {
      const response = await getFinancial(
        "",
        "",
        "",
        branchToSend || "",
        token || ""
      );
      setFinancialRecords(response);
    } catch (error) {
      console.error("Failed to fetch financial data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFilteredData = async () => {
    if (
      !financeTypeHome &&
      !selectedStartDate &&
      !selectedEndDate &&
      !selectedBranchHome
    ) {
      fetchFinancialData();
      return;
    }

    setLoading(true);
    try {
      const from = selectedStartDate
        ? selectedStartDate.format("YYYY-MM-DD")
        : "";
      const to = selectedEndDate ? selectedEndDate.format("YYYY-MM-DD") : "";

      const branchToSend =
        userRole === "super-admin" ? selectedBranchHome : userBranch;

      // Gọi API với các tham số đã chuẩn bị
      const response = await getFinancial(
        financeTypeHome,
        from,
        to,
        branchToSend || "",
        token || ""
      );

      setFinancialRecords(response);
    } catch (error) {
      console.error("Failed to fetch financial data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDocument = (mediaUrl: string) => {
    const viewerUrl = `https://docs.google.com/gview?url=${mediaUrl}&embedded=true`;
    setDocumentUrl(viewerUrl);
    setModalOpen(true);
  };

  useEffect(() => {
    fetchFilteredData();
  }, [financeType, financeDate, selectedBranch]);

  useEffect(() => {
    fetchFinancialData();
  }, []);

  const handleSearch = () => {
    fetchFilteredData();
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    record: any
  ) => {
    setAnchorEl(event.currentTarget);

    setSelectedRecord(record);
  };

  const handleOpenFinancialUpdate = () => {
    setOpenFinancialUpdate(true);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setSelectedRecord(null);
    setAnchorEl(null);
  };

  const handleCloseFinancialUpdate = () => {
    setOpenFinancialUpdate(false);

    setSelectedRecord(null);
  };

  const handleOpenFinancial = () => {
    setFileName(null);
    setFileSrc(null);
    setMediaId("");

    setOpenFinancial(true);
    fetchBranches();
  };
  const handleCloseFinancial = (): void => setOpenFinancial(false);

  const handleBranchChangeHome = (event: any) => {
    setSelectedBranchHome(event.target.value);
  };

  const handleBranchChange = (event: any) => {
    setSelectedBranch(event.target.value);
  };

  const handleDateChange = (newValue: any) => {
    setFinanceDate(newValue);
  };

  const handleFinanceTypeChange = (event: SelectChangeEvent<string>): void => {
    setFinanceType(event.target.value);
  };

  const handleFinanceTypeChangeHome = (
    event: SelectChangeEvent<string>
  ): void => {
    setFinanceTypeHome(event.target.value);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setTitle(event.target.value);
  };

  const handleDownload = (record: any) => {
    if (record && record.media_id && record.media_id.media_url) {
      const mediaUrl = record.media_id.media_url;

      const link = document.createElement("a");
      link.href = mediaUrl;
      link.target = "_blank";
      link.download = "";

      link.click();

      handleCloseMenu();
    } else {
      console.log("Không có file để tải về");
    }
  };

  const handleFileChange = async (event: any) => {
    try {
      const files = event.target.files;
      console.log("file", files);
      if (files && files.length > 0) {
        const file = files[0];
        const headers = {
          "X-Authorization": token,
        };

        const { id, src, fileName } = await uploadMedia(file, headers);

        setFileName(fileName);
        setFileSrc(src);
        setMediaId(id);
      }
    } catch (error) {
      showSnackbar("Upload failed:", "error");
    } finally {
      showSnackbar("Upload complete", "success");
    }
  };

  const handleDeleteFile = () => {
    setFileName(null);
    setFileSrc(null);
  };

  const handleSubmit = async () => {
    try {
      if (!title || !financeType || !mediaId || !financeDate) {
        console.error("Please fill all required fields");
        return;
      }

      const branchToSend =
        userRole === "super-admin" ? selectedBranchHome : userBranch;

      await createFinancial(
        title,
        financeType,
        mediaId,
        financeDate,
        branchToSend || "",
        token || ""
      );

      fetchFinancialData();
      handleCloseFinancial();
      showSnackbar("Financial created successfully!", "success");
    } catch (error) {
      showSnackbar("Error creating financial:", "error");
    }
  };

  const handleSubmitUpdate = async () => {
    if (!selectedRecord) return;

    try {
      const branchToSend = selectedRecord.branch;

      await updateFinancial(
        selectedRecord._id,
        selectedRecord.finance_name,
        selectedRecord.finance_type,
        mediaId || selectedRecord.media_id?._id,
        selectedRecord.finance_date,
        branchToSend,
        token || ""
      );

      fetchFinancialData();

      showSnackbar("Update successful:", "success");
      handleCloseFinancialUpdate();
    } catch (error) {
      showSnackbar("Failed to update financial:", "error");
    }
  };

  const formatText = (text: string, wordLimit: number = 10) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const paginatedRecords = financialRecords.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleDelete = async () => {
    if (!selectedRecord) return;

    try {
      await deleteFinancial(selectedRecord._id, token || "");

      fetchFinancialData();

      showSnackbar("Xóa thành công", "success");

      handleCloseMenu();
    } catch (error) {
      showSnackbar("Xóa thất bại", "error");
      console.error("Failed to delete financial:", error);
    }
  };

  const getBranchTitle = (branchKey: string): string => {
    const branch = branches.find((b) => b.key === branchKey);
    return branch ? branch.title : branchKey;
  };

  return (
    <Box p={2} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={600}>
          Báo cáo tài chính
        </Typography>
        {userRole !== "user" && (
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontWeight: 550,
              bgcolor: "#FF1919",
            }}
            onClick={handleOpenFinancial}
          >
            Tạo báo cáo
          </Button>
        )}
      </Stack>

      <Box width="100%" mt={4} mb={2}>
        <Stack direction="row" gap={1}>
          <Stack width="100%" direction="column" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="finance-type-label">Loại tài chính</InputLabel>
              <Select
                labelId="finance-type-label"
                value={financeTypeHome}
                onChange={handleFinanceTypeChangeHome}
                label="Loại tài chính"
              >
                <MenuItem value="revenue">Doanh thu</MenuItem>
                <MenuItem value="income">Thu nhập</MenuItem>
                <MenuItem value="report">Báo cáo</MenuItem>
              </Select>
            </FormControl>
            {userRole === "admin" &&
            branches.length > 0 &&
            branches.some((branch) => branch.key === userBranch) ? (
              <Typography variant="body1" fontWeight={600}>{`Chi nhánh: ${
                branches.find((branch) => branch.key === userBranch)?.title
              }`}</Typography>
            ) : userRole === "super-admin" ? (
              <FormControl fullWidth size="small">
                <InputLabel id="branch-type-label">Chi nhánh</InputLabel>
                <Select
                  labelId="branch-type-label"
                  value={selectedBranchHome}
                  label="Chi nhánh"
                  fullWidth
                  onChange={handleBranchChangeHome}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.key} value={branch.key}>
                      {branch.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Stack>
          <Box width="100%">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2}>
                <DatePicker
                  label="Chọn ngày bắt đầu"
                  value={selectedStartDate}
                  onChange={(newValue) => {
                    setSelectedStartDate(newValue);
                    selectedStartDateRef.current = newValue;
                  }}
                  slotProps={{ textField: { size: "small" } }}
                  sx={{ width: "100%" }}
                />
                <DatePicker
                  label="Chọn ngày kết thúc"
                  value={selectedEndDate}
                  onChange={(newValue) => {
                    setSelectedEndDate(newValue);
                    selectedEndDateRef.current = newValue;
                  }}
                  slotProps={{ textField: { size: "small" } }}
                  sx={{ width: "100%" }}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Stack minWidth={100} gap={2}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                textTransform: "none",
                height: "40px",
                fontWeight: 550,
                bgcolor: "#FF1919",
              }}
              onClick={handleSearch}
            >
              Tìm kiếm
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                textTransform: "none",
                height: "40px",
                fontWeight: 550,
                bgcolor: "#5771DF",
              }}
              onClick={fetchFinancialData}
            >
              Tất cả
            </Button>
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ width: "100%", overflowX: "auto", marginTop: "20px" }}>
        <Table sx={{ minWidth: 650, boxShadow: 3 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f6f8fa", color: "#fff" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Tên file</TableCell>
              {/* <TableCell sx={{ fontWeight: "bold" }}>Người tạo</TableCell> */}
              <TableCell sx={{ fontWeight: "bold" }}>Thể loại</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Chi nhánh</TableCell>
              {/* <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell> */}
              <TableCell sx={{ fontWeight: "bold" }}>Ngày tạo</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRecords.map((record, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                    cursor: "pointer",
                  },
                  backgroundColor: index % 2 === 0 ? "#fafafa" : "#ffffff",
                }}
              >
                <TableCell>
                  <Stack direction="row" gap={1} alignItems="center">
                    {getFileIcon(record.media_id?.media_file_name)}
                    <strong
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleViewDocument(record.media_id?.media_url)
                      }
                    >
                      {formatText(record.finance_name)}
                    </strong>
                  </Stack>
                </TableCell>
                {/* <TableCell>{record.user_id?.display_name}</TableCell> */}
                <TableCell
                  sx={{
                    fontWeight: 550,
                    color:
                      record.finance_type === "revenue"
                        ? "#1E88E5"
                        : record.finance_type === "income"
                        ? "#43A047"
                        : "#F4511E",
                  }}
                >
                  {record.finance_type === "revenue"
                    ? "Doanh thu"
                    : record.finance_type === "income"
                    ? "Thu nhập"
                    : "Báo cáo"}
                </TableCell>
                <TableCell sx={{ fontWeight: 550 }}>
                  {getBranchTitle(record.branch)}
                </TableCell>
                {/* <TableCell
                  sx={{
                    fontWeight: 550,
                    color:
                      record.finance_status === "pending"
                        ? "#494949"
                        : "#30FF51",
                  }}
                >
                  {record.finance_status === "pending"
                    ? "Đang chờ"
                    : "Thành công"}
                </TableCell> */}
                <TableCell>
                  {new Date(record.finance_date).toLocaleDateString()}
                </TableCell>{" "}
                <TableCell>
                  <IconButton
                    onClick={(e) => handleMenuClick(e, record)}
                    sx={{ marginRight: "10px" }}
                  >
                    <HiOutlineDotsVertical />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={
                      Boolean(anchorEl) && selectedRecord?._id === record._id
                    }
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleDownload(record)}>
                      Tải về
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleOpenFinancialUpdate();
                      }}
                    >
                      Chỉnh sửa
                    </MenuItem>
                    <MenuItem onClick={handleDelete} sx={{ color: "red" }}>
                      Xóa
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={financialRecords.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Box>

      <FinancialModal
        open={openFinancial}
        onClose={handleCloseFinancial}
        userRole={userRole}
        userBranch={userBranch}
        branches={branches}
        selectedBranch={selectedBranch}
        financeType={financeType}
        financeDate={financeDate}
        title={title}
        fileName={fileName}
        handleTitleChange={handleTitleChange}
        handleBranchChange={handleBranchChange}
        handleFinanceTypeChange={handleFinanceTypeChange}
        handleDateChange={handleDateChange}
        handleFileChange={handleFileChange}
        handleDeleteFile={handleDeleteFile}
        handleSubmit={handleSubmit}
      />

      <ModalUpdateFinancial
        open={openFinancialUpdate}
        handleClose={() => setOpenFinancialUpdate(false)}
        branches={branches}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        fileName={fileName}
        handleFileChange={handleFileChange}
        handleDeleteFile={handleDeleteFile}
        handleSubmitUpdate={handleSubmitUpdate}
        userRole={userRole}
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{ width: "80%", height: "80%", margin: "auto" }}>
          <iframe
            src={documentUrl}
            style={{ width: "100%", height: "100%" }}
            frameBorder="0"
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Financial;
