import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  userId: string | null;
  userRole: string | null;
  userBranch: string | null;
  token: string | null;
  loading: boolean;
}

const initialState: UserState = {
  userId: null,
  userRole: null,
  userBranch: null,
  token: null,
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    setUserRole(state, action: PayloadAction<string>) {
      state.userRole = action.payload;
    },
    setUserBranch(state, action: PayloadAction<string>) {
      state.userBranch = action.payload;
    },
    setUser(
      state,
      action: PayloadAction<{
        userId: string;
        userRole: string;
        userBranch: string;
        token: string;
      }>
    ) {
      state.userId = action.payload.userId;
      state.userRole = action.payload.userRole;
      state.userBranch = action.payload.userBranch;
      state.token = action.payload.token;
    },
    clearUser(state) {
      state.userId = null;
      state.userRole = null;
      state.userBranch = null;
      state.token = null;
    },
  },
});

export const { setUserId, setUserRole, setUserBranch, setUser, clearUser } =
  userSlice.actions;
export default userSlice.reducer;
