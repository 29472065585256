import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";

interface PaymentPriceModalProps {
  open: boolean;
  onClose: () => void;
  totalPrices: {
    qrCode: number;
    cash: number;
    shopeeFood: number;
    grabFood: number;
  };
  loading: boolean;
  selectedStartDate: any;
  selectedEndDate: any;
}

const PaymentPriceModal: React.FC<PaymentPriceModalProps> = ({
  open,
  onClose,
  totalPrices,
  loading,
  selectedStartDate,
  selectedEndDate,
}) => {
  const isMobile = useMediaQuery("(max-width: 820px)");

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90vw" : "600px",
          height: isMobile ? "600px" : "unset",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          Thông tin tổng giá tiền của các cổng thanh toán
        </Typography>

        <Grid container spacing={2}>
          {/* QR Code */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: 2,
                width: "100%",
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "0.2s ease",
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }}
            >
              <Box p={1}>
                <Typography
                  variant="body1"
                  color="#333"
                  fontWeight={550}
                  mb={1}
                >
                  QR Code
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    totalPrices.qrCode.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })
                  )}
                </Typography>
              </Box>

              <Box bgcolor="#fafafa" width="100%" p={1.5}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={550}
                >
                  Từ ngày đến ngày:{" "}
                  {selectedStartDate && selectedEndDate
                    ? `${selectedStartDate.format(
                        "DD/MM/YYYY"
                      )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                    : "Tất cả các ngày"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Tiền mặt */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: 2,
                width: "100%",
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "0.2s ease",
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }}
            >
              <Box p={1}>
                <Typography
                  variant="body1"
                  color="#333"
                  fontWeight={550}
                  mb={1}
                >
                  Tiền mặt
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    totalPrices.cash.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })
                  )}
                </Typography>
              </Box>

              <Box bgcolor="#fafafa" width="100%" p={1.5}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={550}
                >
                  Từ ngày đến ngày:{" "}
                  {selectedStartDate && selectedEndDate
                    ? `${selectedStartDate.format(
                        "DD/MM/YYYY"
                      )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                    : "Tất cả các ngày"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Shopee Food */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: 2,
                width: "100%",
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "0.2s ease",
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }}
            >
              <Box p={1}>
                <Typography
                  variant="body1"
                  color="#333"
                  fontWeight={550}
                  mb={1}
                >
                  Shopee Food
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    totalPrices.shopeeFood.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })
                  )}
                </Typography>
              </Box>

              <Box bgcolor="#fafafa" width="100%" p={1.5}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={550}
                >
                  Từ ngày đến ngày:{" "}
                  {selectedStartDate && selectedEndDate
                    ? `${selectedStartDate.format(
                        "DD/MM/YYYY"
                      )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                    : "Tất cả các ngày"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Grab Food */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: 2,
                width: "100%",
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "0.2s ease",
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }}
            >
              <Box p={1}>
                <Typography
                  variant="body1"
                  color="#333"
                  fontWeight={550}
                  mb={1}
                >
                  Grab Food
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    totalPrices.grabFood.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })
                  )}
                </Typography>
              </Box>

              <Box bgcolor="#fafafa" width="100%" p={1.5}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={550}
                >
                  Từ ngày đến ngày:{" "}
                  {selectedStartDate && selectedEndDate
                    ? `${selectedStartDate.format(
                        "DD/MM/YYYY"
                      )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                    : "Tất cả các ngày"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PaymentPriceModal;
