// src/components/FinancialModal.tsx
import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FiDelete } from "react-icons/fi";

interface FinancialModalProps {
  open: boolean;
  onClose: () => void;
  userRole: string | null;
  userBranch: string | null;
  branches: Array<{ key: string; title: string }>;
  selectedBranch: string;
  financeType: string;
  financeDate: any;
  title: string;
  fileName: string | null;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBranchChange: (e: SelectChangeEvent<string>) => void;
  handleFinanceTypeChange: (e: SelectChangeEvent<string>) => void;
  handleDateChange: (newDate: any) => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: () => void;
  handleSubmit: () => void;
}

const FinancialModal: React.FC<FinancialModalProps> = ({
  open,
  onClose,
  userRole,
  userBranch,
  branches,
  selectedBranch,
  financeType,
  financeDate,
  title,
  fileName,
  handleTitleChange,
  handleBranchChange,
  handleFinanceTypeChange,
  handleDateChange,
  handleFileChange,
  handleDeleteFile,
  handleSubmit,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Nhập tiêu đề báo cáo
        </Typography>

        <TextField
          fullWidth
          label="Tiêu đề"
          value={title}
          onChange={handleTitleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="space-between"
          mb={2}
        >
          {userRole === "admin" && userBranch === "duongkhue" ? (
            <div>{`Chi nhánh: Dương Khuê`}</div>
          ) : userRole === "super-admin" ? (
            <FormControl fullWidth>
              <InputLabel id="branch-type-label">Chi nhánh</InputLabel>
              <Select
                labelId="finance-type-label"
                value={selectedBranch}
                label="Chi nhánh"
                fullWidth
                onChange={handleBranchChange}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.key} value={branch.key}>
                    {branch.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          <FormControl fullWidth>
            <InputLabel id="finance-type-label">Loại tài chính</InputLabel>
            <Select
              labelId="finance-type-label"
              value={financeType}
              onChange={handleFinanceTypeChange}
              label="Loại tài chính"
            >
              <MenuItem value="revenue">Doanh thu</MenuItem>
              <MenuItem value="income">Thu nhập</MenuItem>
              <MenuItem value="report">Báo cáo</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Chọn ngày bắt đầu"
            value={financeDate}
            onChange={handleDateChange}
            sx={{ width: "100%", mb: 2 }}
          />
        </LocalizationProvider>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "40px",
            cursor: "pointer",
            mb: 6,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border: "2px dashed #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              transition: "background-color 0.3s",
            }}
            onClick={() => document.getElementById("fileInput")?.click()}
          >
            <Typography>Nhấn vào đây để tải tài liệu</Typography>

            <input
              id="fileInput"
              type="file"
              accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>

          {fileName && (
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ flex: 1 }}>
                Tên file: <strong>{fileName}</strong>
              </Typography>
              <IconButton
                color="error"
                onClick={handleDeleteFile}
                sx={{
                  padding: 0,
                  marginLeft: "10px",
                  width: "24px",
                  height: "24px",
                }}
              >
                <FiDelete />
              </IconButton>
            </Box>
          )}
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
        >
          Xác nhận
        </Button>
      </Box>
    </Modal>
  );
};

export default FinancialModal;
