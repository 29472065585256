import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { formatCurrency, formatDateTime } from "src/utils/formatCurrency";
import SearchComponent from "./conponents/SearchComponent";
import {
  createBill,
  getBranchConfig,
  getListOrder,
  getListOrderPayment,
  getListStatics,
  updateStatus,
} from "./service";

import "./assets/css/Order.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { IoClose } from "react-icons/io5";
import isBetween from "dayjs/plugin/isBetween";
import { GridExpandMoreIcon, GridMoreVertIcon } from "@mui/x-data-grid";
import { useSnackbar } from "src/components/CustomSnackbar";
import { IoCalendarOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { BranchIT } from "src/pages/Branch/interface";
import { RootState } from "src/redux-persist/store";
import { useSelector } from "react-redux";
import { useToken } from "src/utils/token";
import PaymentPriceModal from "./components/ModalPaymentPrice";

type OrderStatus = "pending" | "success" | "served" | "close";

const Order = () => {
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const token = useToken();
  const userId = useSelector((state: RootState) => state.user.userId);
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [branches, setBranches] = useState<BranchIT[]>([]);

  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("served");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedBranchCreate, setSelectedBranchCreate] = useState<string>("");
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    dayjs()
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(dayjs());
  const [platform, setPlatform] = useState<string>("");
  const [totalPrices, setTotalPrices] = useState({
    qrCode: 0,
    cash: 0,
    shopeeFood: 0,
    grabFood: 0,
  });
  const [totalStatics, setTotalStatics] = useState({
    totalCount: 0,
    totalPrice: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [soldItems, setSoldItems] = useState<any[]>([]);
  const [selectedUpdateStatus, setSelectedUpdateStatus] = useState<
    OrderStatus | ""
  >("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenPaymentPrice, setModalOpenPaymentPrice] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [openCreateBill, setOpenCreateBill] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<Dayjs | null>(dayjs());

  const stackRef = useRef(null);
  const selectedStartDateRef = useRef<Dayjs | null>(dayjs());
  const selectedEndDateRef = useRef<Dayjs | null>(dayjs());

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  const handleOpenCreateBill = (): void => setOpenCreateBill(true);
  const handleCloseCreateBill = (): void => setOpenCreateBill(false);

  const formatCurrencys = (value: string): string => {
    const numericValue = value.replace(/\D/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const inputValue = event.target.value;
    const formattedValue = formatCurrencys(inputValue);
    setAmount(formattedValue);
  };

  const handlePlatformChange = (event: SelectChangeEvent<string>): void => {
    setPlatform(event.target.value);
  };

  const fetchBranches = async () => {
    try {
      const data = await getBranchConfig("branch", token || "");
      setBranches(data.config.option_content);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchOrdersAndStatics = async () => {
    setLoading(true);
    try {
      const startDate = selectedStartDate
        ? selectedStartDate.format("YYYY-MM-DD")
        : undefined;
      const endDate = selectedEndDate
        ? selectedEndDate.add(1, "day").format("YYYY-MM-DD")
        : undefined;

      const orderResponse = await getListOrder(
        selectedBranch,
        rowsPerPage,
        page + 1,
        selectedStatus,
        startDate,
        endDate,
        token || ""
      );

      const staticsResponse = await getListStatics(
        selectedBranch,
        rowsPerPage,
        page + 1,
        selectedStatus,
        startDate,
        endDate,
        token || ""
      );

      setOrders(orderResponse?.data || []);
      setFilteredOrders(orderResponse?.data || []);
      setSoldItems(staticsResponse?.data || []);
      setTotalStatics({
        totalCount: staticsResponse?.totalCount || 0,
        totalPrice: staticsResponse?.totalPrice || 0,
      });

      setTotalRecords(orderResponse?.totalCount || 0);
      setTotalPrice(orderResponse?.totalPrice || 0);
    } catch (error) {
      console.error("Error fetching orders and statics:", error);
    } finally {
      setLoading(false);
      setInitialLoadComplete(true);
    }
  };

  const fetchPaymentPrices = async () => {
    setLoading(true);
    try {
      const startDate = selectedStartDate
        ? selectedStartDate.format("YYYY-MM-DD")
        : undefined;
      const endDate = selectedEndDate
        ? selectedEndDate.add(1, "day").format("YYYY-MM-DD")
        : undefined;

      const [
        qrCodeResponse,
        cashResponse,
        shopeeFoodResponse,
        grabFoodResponse,
      ] = await Promise.all([
        getListOrderPayment(
          selectedBranch,
          rowsPerPage,
          page + 1,
          selectedStatus,
          startDate,
          endDate,
          "qrcode",
          token || ""
        ),
        getListOrderPayment(
          selectedBranch,
          rowsPerPage,
          page + 1,
          selectedStatus,
          startDate,
          endDate,
          "cash",
          token || ""
        ),
        getListOrderPayment(
          selectedBranch,
          rowsPerPage,
          page + 1,
          selectedStatus,
          startDate,
          endDate,
          "shopeefood",
          token || ""
        ),
        getListOrderPayment(
          selectedBranch,
          rowsPerPage,
          page + 1,
          selectedStatus,
          startDate,
          endDate,
          "grabfood",
          token || ""
        ),
      ]);

      setTotalPrices({
        qrCode: qrCodeResponse.totalPrice,
        cash: cashResponse.totalPrice,
        shopeeFood: shopeeFoodResponse.totalPrice,
        grabFood: grabFoodResponse.totalPrice,
      });
    } catch (error) {
      console.error("Error fetching payment prices:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalOpenPaymentPrice) {
      fetchPaymentPrices(); // Gọi API khi modal mở
    }
  }, [
    modalOpenPaymentPrice,
    selectedBranch,
    selectedStartDate,
    selectedEndDate,
    token,
  ]);

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    fetchOrdersAndStatics();
  }, [
    selectedBranch,
    page,
    rowsPerPage,
    selectedStatus,
    selectedStartDate,
    selectedEndDate,
  ]);

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);

    let startDate = dayjs();
    let endDate = dayjs();

    switch (period) {
      case "today":
        startDate = dayjs();
        endDate = startDate;
        break;
      case "yesterday":
        startDate = dayjs().subtract(1, "day");
        endDate = startDate;
        break;
      case "lastWeek":
        startDate = dayjs().subtract(1, "week").startOf("week");
        endDate = startDate.add(6, "day");
        break;
      case "lastMonth":
        startDate = dayjs().subtract(1, "month").startOf("month");
        endDate = startDate.add(1, "month").subtract(1, "day");
        break;
      case "thisWeek":
        startDate = dayjs().startOf("week");
        endDate = dayjs().endOf("week");
        break;
      case "thisMonth":
        startDate = dayjs().startOf("month");
        endDate = dayjs().endOf("month");
        break;
      default:
        break;
    }

    if (
      !startDate.isSame(selectedStartDate) ||
      !endDate.isSame(selectedEndDate)
    ) {
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    }
  };

  useEffect(() => {
    if (initialLoadComplete || selectedPeriod) {
      fetchOrdersAndStatics();
    }
  }, [
    selectedBranch,
    page,
    rowsPerPage,
    selectedStatus,
    selectedStartDate,
    selectedEndDate,
    initialLoadComplete,
  ]);

  useEffect(() => {
    if (location.state?.selectedBranch) {
      setSelectedBranch(location.state.selectedBranch);
    } else if (userBranch) {
      setSelectedBranch(userBranch);
    } else {
      setSelectedBranch("duongkhue");
    }
  }, [location.state, userBranch]);

  const handleOpen = (): void => setOpenModal(true);
  const handleClose = (): void => setOpenModal(false);

  const handleOpenPaymentPrice = (): void => setModalOpenPaymentPrice(true);
  const handleClosePaymentPrice = (): void => setModalOpenPaymentPrice(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const status = event.target.value as string;
    setSelectedStatus(status);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    orderId: string
  ) => {
    setSelectedOrderId(orderId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    const selectedOrder = filteredOrders.find(
      (order) => order._id === selectedOrderId
    );
    if (selectedOrder) {
      setSelectedUpdateStatus(selectedOrder.status);
    }
    setModalOpen(true);
    handleMenuClose();
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedUpdateStatus("");
  };

  const handleUpdateStatus = async () => {
    if (selectedOrderId && selectedUpdateStatus) {
      try {
        await updateStatus(selectedOrderId, selectedUpdateStatus);

        setFilteredOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === selectedOrderId
              ? { ...order, status: selectedUpdateStatus }
              : order
          )
        );

        setSelectedUpdateStatus("");
        showSnackbar("Thay đổi trạng thái thành công!", "success");
      } catch (error) {
        console.error("Error updating order status:", error);
        showSnackbar("Thay đổi trạng thái thất bại!", "error");
      } finally {
        handleModalClose();
      }
    }
  };

  const handleBranchChange = (event: SelectChangeEvent) => {
    setSelectedBranch(event.target.value as string);
  };

  const handleBranchChangeCreate = (event: SelectChangeEvent) => {
    setSelectedBranchCreate(event.target.value as string);
  };

  const handleCreateOrder = async () => {
    const branchToSend =
      userRole === "super-admin" ? selectedBranchCreate : userBranch;

    const params = {
      user_id: "66fba5a224c14f7e9f55cc11",
      total_amount: amount.replace(/\./g, ""),
      status: "success",
      prdoduct_type: "food",
      payment_method: platform,
      order_note: "Please deliver quickly",
      description: "Order for lunch",
      trans_id: "trans789",
      deep_link: "http://example.com/deep-link",
      bill_number: 1234,
      branch: branchToSend || "",
      is_offline: false,
      createdAt: createdAt?.toISOString() || new Date().toISOString(),
    };

    try {
      const response = await createBill(params, token || "");
      fetchOrdersAndStatics();
      showSnackbar("Tạo đơn hàng thành công!", "success");
      setOpenCreateBill(false);
    } catch (error) {
      showSnackbar("Tạo đơn hàng thất bại!", "error");
    }
  };

  return (
    <Box
      sx={{ mt: isMobile ? 5 : "none" }}
      width="100%"
      p={2}
      pt={4}
      maxWidth={1200}
      margin="0 auto"
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={550}>
          Danh sách đặt hàng
        </Typography>
      </Stack>

      <Stack
        direction={isMobile ? "column" : "row"}
        mb={isMobile ? 2 : "20px"}
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Stack width={isMobile ? "100%" : "500px"}>
          <SearchComponent
            orders={orders}
            setFilteredOrders={setFilteredOrders}
            setIsSearching={setIsSearching}
          />
          <Stack direction="row" alignItems="center" gap={2}>
            {userRole !== "super-admin" ? null : (
              <FormControl
                variant="outlined"
                sx={{ width: "100%" }}
                size="small"
              >
                <InputLabel>Chi nhánh</InputLabel>
                <Select
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  label="Chi nhánh"
                >
                  {loading ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    branches.map((branch) => (
                      <MenuItem
                        key={branch.key}
                        value={branch.key}
                        disabled={
                          userRole !== "super-admin" &&
                          branch.key !== userBranch
                        } // Disable non-assigned branches
                      >
                        {branch.title}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" sx={{ width: "100%" }} size="small">
              <InputLabel>Tình trạng</InputLabel>
              <Select
                value={selectedStatus || ""}
                onChange={handleStatusChange}
                label="Tình trạng"
              >
                <MenuItem value="">Tất cả</MenuItem>
                <MenuItem value="served">Đã giao hàng</MenuItem>
                <MenuItem value="success">Đã thanh toán</MenuItem>
                <MenuItem value="pending">Chưa thanh toán</MenuItem>
                <MenuItem value="close">Hủy thanh toán</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          gap={1}
          justifyContent={isMobile ? "space-between" : "unset"}
          width={isMobile ? "100%" : "unset"}
        >
          {userRole !== "user" && (
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                fontWeight: 550,
                bgcolor: "#FF1919",
              }}
              onClick={handleOpenCreateBill}
            >
              Tạo đơn hàng
            </Button>
          )}
          <Stack alignItems="center" gap={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              bgcolor="#f7f7f7"
              p="10px 10px"
              borderRadius={2}
              gap={1}
              width="100%"
              ref={stackRef}
              sx={{
                cursor: "pointer",
                transition: "background-color 0.2s ease",
                "&:hover": {
                  bgcolor: "#e1e1e1",
                },
              }}
              onClick={() => setShowDatePicker((prev) => !prev)}
            >
              <IoCalendarOutline fontSize={20} />
              <Typography
                variant="body1"
                fontWeight={550}
                display={isMobile ? "none" : "block"}
              >
                Thời gian
              </Typography>
            </Stack>
            <Popover
              open={showDatePicker}
              anchorEl={stackRef.current}
              onClose={() => setShowDatePicker(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box p={2} width="300px">
                <Select
                  value={selectedPeriod}
                  onChange={(event) => handlePeriodChange(event.target.value)}
                  displayEmpty
                  variant="standard"
                  sx={{
                    width: "100%",
                    borderRadius: 2,
                    padding: 0.5,
                    "& .MuiSelect-select": {
                      padding: "8px",
                    },
                  }}
                  renderValue={(value) =>
                    value ? (
                      <Typography fontWeight="550" color="#333">
                        {value === "today"
                          ? "Ngày hôm nay"
                          : value === "yesterday"
                          ? "Ngày hôm qua"
                          : value === "thisWeek"
                          ? "Tuần này"
                          : value === "lastWeek"
                          ? "Tuần vừa qua"
                          : value === "thisMonth"
                          ? "Tháng này"
                          : "Tháng vừa qua"}
                      </Typography>
                    ) : (
                      <Typography color="text.secondary">
                        Chọn thời gian
                      </Typography>
                    )
                  }
                >
                  <MenuItem value="today">
                    <Typography>Ngày hôm nay</Typography>
                  </MenuItem>
                  <MenuItem value="yesterday">
                    <Typography>Ngày hôm qua</Typography>
                  </MenuItem>
                  <MenuItem value="thisWeek">
                    <Typography>Tuần này</Typography>
                  </MenuItem>
                  <MenuItem value="lastWeek">
                    <Typography>Tuần vừa qua</Typography>
                  </MenuItem>
                  <MenuItem value="thisMonth">
                    <Typography>Tháng này</Typography>
                  </MenuItem>
                  <MenuItem value="lastMonth">
                    <Typography>Tháng vừa qua</Typography>
                  </MenuItem>
                </Select>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2} mt={2}>
                    <DatePicker
                      label="Chọn ngày bắt đầu"
                      value={selectedStartDate}
                      onChange={(newValue) => {
                        setSelectedStartDate(newValue);
                        selectedStartDateRef.current = newValue;
                        fetchOrdersAndStatics();
                      }}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ width: "100%" }}
                    />
                    <DatePicker
                      label="Chọn ngày kết thúc"
                      value={selectedEndDate}
                      onChange={(newValue) => {
                        setSelectedEndDate(newValue);
                        selectedEndDateRef.current = newValue;
                        fetchOrdersAndStatics();
                      }}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ width: "100%" }}
                    />
                  </Stack>
                </LocalizationProvider>

                {selectedStartDate && selectedEndDate && (
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={2}
                    bgcolor="#f7f7f7"
                    textAlign="center"
                    justifyContent="center"
                    borderRadius={1}
                  >
                    <Typography
                      variant="body1"
                      sx={{ mr: 1, pl: 1, fontWeight: 550 }}
                    >
                      {selectedStartDate.format("YYYY/MM/DD")} -{" "}
                      {selectedEndDate.format("YYYY/MM/DD")}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setSelectedStartDate(null);
                        setSelectedEndDate(null);
                        setSelectedPeriod("");

                        fetchOrdersAndStatics();
                      }}
                    >
                      <IoClose />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Popover>
          </Stack>
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        {/* Box 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            onClick={handleOpenPaymentPrice}
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              borderRadius: 2,
              width: "100%",
              height: 150,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              transition: "0.2s ease",
              cursor: "pointer",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
            }}
          >
            <Box p={1}>
              <Typography variant="body1" color="#333" fontWeight={550} mb={1}>
                Tổng giá tiền
              </Typography>
              <Typography variant="h5" fontWeight={700}>
                {formatCurrency(totalPrice)}
              </Typography>
            </Box>

            <Box bgcolor="#fafafa" width="100%" p={1.5}>
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={550}
              >
                {selectedStartDate && selectedEndDate
                  ? `Từ ngày: ${selectedStartDate.format(
                      "DD/MM/YYYY"
                    )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                  : "Toàn bộ các ngày"}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Box 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            onClick={handleOpen}
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              borderRadius: 2,
              width: "100%",
              height: 150,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              transition: "0.2s ease",
              cursor: "pointer",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
            }}
          >
            <Box p={1}>
              <Typography variant="body1" color="#333" fontWeight={550} mb={1}>
                Tổng số món bán được
              </Typography>
              <Typography variant="h5" fontWeight={700}>
                {totalStatics?.totalCount}
              </Typography>
            </Box>

            <Box bgcolor="#fafafa" width="100%" p={1.5}>
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={550}
              >
                {selectedStartDate && selectedEndDate
                  ? `Từ ngày: ${selectedStartDate.format(
                      "DD/MM/YYYY"
                    )} - ${selectedEndDate.format("DD/MM/YYYY")}`
                  : "Toàn bộ các ngày"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Tổng số món bán được</DialogTitle>
        <DialogContent sx={{ maxHeight: "400px", overflowY: "auto" }}>
          {" "}
          {/* Thêm khả năng cuộn */}
          <Stack spacing={2} mt={2}>
            {soldItems.map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <img
                    src={item.product_id?.url}
                    alt={item.product_id?.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                  />
                  <Box>
                    <Typography variant="body1">
                      {item.product_id?.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Giá:{" "}
                      {item.product_id?.variants?.[0]?.price?.toLocaleString() ||
                        "N/A"}{" "}
                      VND
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1">Đã bán: {item.count}</Typography>
              </Box>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Đóng
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          mt: 3,
          borderRadius: "8px",
          maxWidth: isMobileSmall ? "365px" : isIphone14 ? "380px" : "100%",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            mt: 3,
            minWidth: 680,
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : filteredOrders.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Không có dữ liệu nào
              </Typography>
            </Box>
          ) : (
            <Table>
              <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 550 }}>STT</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Đơn đặt hàng</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Thể loại</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Tình trạng</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Giá tiền</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Cổng</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Ngày tạo</TableCell>
                  {userRole !== "user" && (
                    <TableCell sx={{ fontWeight: 550 }}>Hành động</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders.map((order, index) => (
                  <Tooltip
                    key={order._id}
                    title={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          bgcolor: "#fff",
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "4px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <Box sx={{ bgcolor: "#333", p: 1 }}>
                          <Typography variant="body1">
                            Đơn hàng: <strong>#{order.bill_number}</strong>
                          </Typography>
                        </Box>
                        {order.cart_ids.map((cart: any, index: any) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            color="#333"
                            p={1}
                          >
                            <img
                              src={cart.product_id?.url}
                              alt={cart.product_id?.name}
                              style={{ width: "70px", marginRight: "8px" }}
                            />
                            <Stack>
                              <Typography variant="body1">
                                {cart.product_id?.name}
                              </Typography>
                              <Typography variant="body2" fontWeight={550}>
                                {cart.amount} -{" "}
                                {formatCurrency(
                                  cart.product_id?.variants[0]?.price
                                )}
                              </Typography>
                            </Stack>
                          </Box>
                        ))}
                        <Divider />
                        <Stack
                          sx={{ p: 1 }}
                          color="#333"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>Tổng:</Typography>
                          <Typography fontWeight={550}>
                            {formatCurrency(
                              order.cart_ids.reduce(
                                (total: number, cart: any) => {
                                  return (
                                    total +
                                    cart.amount *
                                      cart.product_id?.variants[0]?.price
                                  );
                                },
                                0
                              )
                            )}
                          </Typography>
                        </Stack>
                      </Box>
                    }
                    placement="top-start"
                  >
                    <TableRow
                      sx={{
                        transition: "0.2s ease",
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.02)" },
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>{order.bill_number}</TableCell>
                      <TableCell sx={{ fontWeight: 550 }}>
                        {order.payment_method === "shopeefood"
                          ? "Shopee Food"
                          : order.payment_method === "grabfood"
                          ? "Grab Food"
                          : order.table
                          ? `Bàn ${order.table}`
                          : "Mang về"}
                      </TableCell>

                      <TableCell
                        sx={{
                          color:
                            order.status === "success"
                              ? "#4CAF50"
                              : order.status === "served"
                              ? "green"
                              : order.status === "close"
                              ? "gray"
                              : "red",
                          fontWeight: 550,
                        }}
                      >
                        {order.status === "served"
                          ? "Đã giao hàng"
                          : order.status === "close"
                          ? "Hủy thanh toán"
                          : order.status === "success"
                          ? "Đã thanh toán"
                          : "Chưa thanh toán"}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 550 }}>
                        {formatCurrency(order?.price)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 550 }}>
                        {" "}
                        {order.payment_method === "shopeefood"
                          ? "Shopee Food"
                          : order.payment_method === "grabfood"
                          ? "Grab Food"
                          : order.payment_method === "qrcode"
                          ? "Quét QR"
                          : "Tiền mặt"}
                      </TableCell>
                      <TableCell>{formatDateTime(order.createdAt)}</TableCell>
                      {userRole !== "user" && (
                        <>
                          <TableCell>
                            <IconButton
                              onClick={(event) =>
                                handleMenuClick(event, order._id)
                              }
                            >
                              <GridMoreVertIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={handleModalOpen}>
                                Thay đổi trạng thái
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} of ${
              count !== -1 ? count : `more than ${to}`
            }`;
          }}
        />
      </Box>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Thay đổi trạng thái
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Chọn trạng thái</InputLabel>
            <Select
              value={selectedUpdateStatus}
              onChange={(event) =>
                setSelectedUpdateStatus(event.target.value as OrderStatus)
              }
              label="Chọn trạng thái"
              displayEmpty
            >
              <MenuItem value="pending">Chưa thanh toán</MenuItem>
              <MenuItem value="success">Đã thanh toán</MenuItem>
              <MenuItem value="served">Đã giao hàng</MenuItem>
              <MenuItem value="close">Hủy thanh toán</MenuItem>
            </Select>
          </FormControl>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            <Button onClick={handleModalClose} color="primary">
              Đóng
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateStatus}
              color="primary"
            >
              Thay đổi
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal open={openCreateBill} onClose={handleCloseCreateBill}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Tạo đơn hàng
          </Typography>
          <TextField
            fullWidth
            label="Số tiền"
            value={amount}
            onChange={handleAmountChange}
            variant="outlined"
            type="text"
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="platform-select-label">Nền tảng</InputLabel>
            <Select
              labelId="platform-select-label"
              value={platform}
              onChange={handlePlatformChange}
              label="Nền tảng"
            >
              <MenuItem value="shopeefood">Shopee Food</MenuItem>
              <MenuItem value="grabfood">Grab Food</MenuItem>
            </Select>
          </FormControl>
          {userRole !== "super-admin" ? null : (
            <FormControl variant="outlined" sx={{ width: "100%", mb: 2 }}>
              <InputLabel>Chi nhánh</InputLabel>
              <Select
                value={selectedBranchCreate}
                onChange={handleBranchChangeCreate}
                label="Chi nhánh"
              >
                {loading ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  branches.map((branch) => (
                    <MenuItem
                      key={branch.key}
                      value={branch.key}
                      disabled={
                        userRole !== "super-admin" && branch.key !== userBranch
                      }
                    >
                      {branch.title}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Chọn ngày"
              value={createdAt}
              onChange={(newValue) => setCreatedAt(newValue)}
              sx={{ width: "100%", mb: 2 }}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#FF1919" }}
            onClick={handleCreateOrder}
            fullWidth
          >
            Xác nhận
          </Button>
        </Box>
      </Modal>

      <PaymentPriceModal
        open={modalOpenPaymentPrice}
        onClose={() => setModalOpenPaymentPrice(false)}
        totalPrices={totalPrices}
        loading={loading}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
      />
    </Box>
  );
};

export default Order;
