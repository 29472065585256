import axios, { AxiosRequestConfig } from "axios";

export const getFinancial = async (
  finance_type: string,
  from: string,
  to: string,
  branch: string,
  token: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/finance/list-finance?branch=${branch}&from=${from}&to=${to}&finance_type=${finance_type}`;

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch branch config:", error);
    throw error;
  }
};

export const uploadMedia = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  const TIMEOUT_DURATION = 30 * 60 * 1000;
  let uploadTimeout: NodeJS.Timeout | null = null;

  try {
    const formData = new FormData();
    formData.append("file[]", file);

    const config: AxiosRequestConfig = {
      method: "post",
      url: `https://media.exam24h.com/upload-file?callback=${process.env.REACT_APP_API_URL}/api/media/create`,
      headers,
      data: formData,
      timeout: TIMEOUT_DURATION,
    };

    uploadTimeout = setTimeout(() => {
      console.error("Upload timed out");
      throw new Error("Upload timed out");
    }, TIMEOUT_DURATION);

    const response = await axios.request(config);

    clearTimeout(uploadTimeout);

    if (
      response.data &&
      response.data[0] &&
      response.data[0].callback &&
      response.data[0].callback._id &&
      response.data[0].callback.media_file_name &&
      response.data[0].src
    ) {
      return {
        id: response.data[0].callback._id,
        src: response.data[0].src,
        fileName: response.data[0].callback.media_file_name,
      };
    } else {
      throw new Error("Upload failed: Invalid response");
    }
  } catch (error) {
    if (uploadTimeout) {
      clearTimeout(uploadTimeout);
    }
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const createFinancial = async (
  finance_name: string,
  finance_type: string,
  media_id: string,
  finance_date: string,
  branch: string,
  token: string
): Promise<any> => {
  const requestData = {
    finance_name,
    finance_type,
    media_id,
    finance_date,
    branch,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/finance/create-finance";

  try {
    const response = await axios.post(url, requestData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create category: ${error.message}`);
  }
};

export const updateFinancial = async (
  _id: string,
  finance_name: string,
  finance_type: string,
  media_id: string,
  finance_date: string,
  branch: string,
  token: string
): Promise<any> => {
  const requestData = {
    _id,
    finance_name,
    finance_type,
    media_id,
    finance_date,
    branch,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": token,
    },
  };

  const url = process.env.REACT_APP_API_URL + "/api/finance/update-finance";

  try {
    const response = await axios.patch(url, requestData, axiosConfig);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create category: ${error.message}`);
  }
};

export const deleteFinancial = async (
  _id: string,
  token: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/api/finance/delete-finance/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": token,
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
